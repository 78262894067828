
import { request as axios } from '@/util/request';
import { Component, Mixins, Vue } from 'vue-property-decorator';
import moment from 'moment';
const tableData=[
    {
    title: '骑手ID',
    body:'000',
    img:false,
  },
  {
    title: '骑手姓名',
    body: '的说法是',
    dataIndex: 'name',
    img:false,
  },
  {
    title: '联系电话',
    body: '15069355494894',
    dataIndex: 'phone',
    img:false,
  },
  {
    title: '身份证号',
    body: '8498494984',
    dataIndex: 'sfzh',
    img:false,
  },
  {
    title: '意向区域',
    body: '898',
    dataIndex: 'city',
    img:false,
  },
  {
    title: '身份证照片',
    body: [''],
    dataIndex: 'img',
    img:true,
  },
  {
    title: '健康证号',
    body: '9898999',
    dataIndex: 'jkzh',
    img:false,
  },
  {
    title: '发证城市',
    body: 'reweg',
    dataIndex: 'fcity',
    img:false,
  },
  {
    title: '发证日期',
    body: '2002',
    dataIndex: 'data',
    img:false,
  },
  {
    title: '健康证照片',
    body: [''],
    dataIndex: 'img',
    img:true,
  },
  {
    title: '紧急联系人姓名',
    body: 'wegeth',
    dataIndex: 'jjname',
    img:false,
  },
  {
    title: '紧急联系人电话',
    body: 'jjphone',
    dataIndex: 'jjphone',
    img:false,
  },
  {
    title: '注册时间',
    body: 'time',
    dataIndex: 'time',
    img:false,
  },
  {
    title: '提交日期',
    body: moment,
    dataIndex: 'times',
    img:false,
  },
]
const zhanghuInfo=[
  {
    title: '今日送单量',
    body:0,
  },
  {
    title: '累计送单量',
    body:0,
  },
  {
    title: '今日收入',
    body:0,
  },
  {
    title: '累计收入',
    body:0,
  },
  {
    title: '账户余额',
    body:0,
  },
  {
    title: '提现金额',
    body:0,
  },
]
@Component
export default class Knightdetails extends Vue{
    preview_image: string = ''; // 示例图片预览路径
    preview_visible: boolean = false; // 控制示例图片显示和隐藏
    tableData= tableData //表格数据
    shenhedis: boolean=false//审核弹窗显隐
    shenheYes: number=3 //审核是否通过
    shenhePass: boolean=false//审核不通过字段显隐
    passBecause: string=''//审核不通过原因
    log: any=[]
    zhanghuInfo= zhanghuInfo
    shenheanniu: boolean=false

    //获取骑手详细信息
    async getData(){
      this.tableData[5].body=[]
      this.tableData[9].body=[]
      const res: any=await axios.get(`/boss/knight/management/${this.$route.query.register_id}`)
      this.tableData[0].body=res.data.id
      this.tableData[1].body=res.data.name
      this.tableData[2].body=res.data.mobile
      this.tableData[3].body=res.data.qualification!=null? res.data.qualification.id_number:''
      this.tableData[4].body=res.data.qualification!=null?res.data.qualification.province+res.data.qualification.city+res.data.qualification.district:''
      this.tableData[5].body.push(String(res.data.qualification.id_front))
      this.tableData[5].body.push(String(res.data.qualification.id_back))
      this.tableData[5].body.push(String(res.data.qualification.id_held))
      this.tableData[6].body=res.data.ext!=null? res.data.ext.health_number:''
      this.tableData[7].body=res.data.ext!=null?res.data.ext.health_province+res.data.ext.health_city:''
      this.tableData[8].body=res.data.ext!=null?res.data.ext.certificate_date:''
      res.data.ext!=null?this.tableData[9].body.push(String(res.data.ext.health_front)):''
      res.data.ext!=null?this.tableData[9].body.push(String(res.data.ext.health_back)):''
      this.tableData[10].body=res.data.ext!=null?res.data.ext.contact_name:''
      this.tableData[11].body=res.data.ext!=null?res.data.ext.contact_mobile:''
      this.tableData[12].body=moment(res.data.create_time*1000). format('YYYY-MM-DD HH:mm:ss')
      this.tableData[13].body=moment(res.data.qualification.create_time* 1000).format('YYYY-MM-DD HH:mm:ss')
      this.zhanghuInfo[0].body=res.data.ext!=null? res.data.ext.delivered_count_today:0
      this.zhanghuInfo[1].body=res.data.ext!=null? res.data.ext.delivered_count:0
      this.zhanghuInfo[2].body=res.data.ext!=null? res.data.ext.income_today/100:0
      this.zhanghuInfo[3].body=res.data.ext!=null? res.data.ext.income/100:0
      this.zhanghuInfo[4].body=res.data.wallet!=null? res.data.wallet.balance/100:0
      this.zhanghuInfo[5].body=this.zhanghuInfo[3].body-this.zhanghuInfo[4].body
      this.log=res.data.log
      console.log(this.log)
    }
    async created(){
        this.tableData=tableData
        await this.getData()
        this.$route.query.status=='1'?this.shenheanniu=true:this.shenheanniu=false
    }
    //确定
    async handleOk(){
        if(this.shenheYes==2&&this.passBecause==''){
            this.$message.error('请填写不通过原因')
            return false
        }else{
            //调用审核接口
            const body: any={
              status:this.shenheYes,
              reason:this.passBecause,
              register_id :this.$route.query.register_id
            }
            if(this.shenheYes==3){
              delete body.reason
            }
            const res=await axios.put(`/boss/knight/management/${this.$route.query.id}`,body)
            if(res.status==200){
              this.shenhedis=false
              this.$message.success('审核成功')
            }else{
              this.$message.error('审核失败')
            }
            this.getData()
            this.shenheanniu=false
        }
    }
    //取消
    handleCancel(){
        this.shenhedis=false;
    }
    //审核按钮点击事件
    shenhe(){
        this.shenhedis=true
    }
    //审核不通过按钮点击事件
    radioPass(){
        this.shenhePass=true
    }
    radioYes(){
        this.shenhePass=false
    }
    // 关闭模态窗
    handlePreviewCancel() {
     this.preview_visible = false;
    }
    // 显示模态窗，改变模态窗中的图片路径
    handlePreview(e: any) {
    this.preview_image = e.target.src;
    this.preview_visible = true;
   }
}
